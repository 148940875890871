import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Kipping (Pullups, Dips, T2B, K2E, Muscle Ups, etc)`}</p>
    <p>{`then,`}</p>
    <p>{`1/2 Mile Run`}</p>
    <p>{`21-Thrusters (75/55)`}</p>
    <p>{`15-Burpees`}</p>
    <p>{`9-Box Jumps (24/20)`}</p>
    <p>{`100 Double Unders`}</p>
    <p>{`21-Burpees`}</p>
    <p>{`15-Box Jumps`}</p>
    <p>{`9-Thrusters`}</p>
    <p>{`500M Row`}</p>
    <p>{`21-Box Jumps`}</p>
    <p>{`15-Thrusters`}</p>
    <p>{`9-Burpees`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`The CrossFit Open starts this Thursday, February 23rd! Sign up now
at: Games.crossfit.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The next Strength Program starts March 4th. Email Eric at
fallscitystrength\\@gmail for more info.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      